module.exports = {
    titleBar : [{
        name:"首页",
        url:"/",
        children:[]
    },{
        name:"普洱产品",
        url:"/brand",
        children:[]
    },{
        name:"文化历史",
        url:"/history",
        children:[]
    },{
        name:"产地工艺",
        url:"/craft",
        children:[]
    },{
        name:"新闻资讯",
        url:"/news/newsList",
        children:[]
    },{
        name:"普洱商城",
        // url:"http://www.mhtea.net",
        url:"https://shop.hbaia.com/",
        banner:"",
        children:[]
    },{
        name:"联系我们",
        url:"/us",
        children:[]
    }]
}