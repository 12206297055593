<template>
	<div class="header">
		<div class="typeArea" ref="typeArea" >
			<div><span class="webName">云南省勐海县数字茶业门户</span></div>
			<img v-if="!screenState" class="headerMenu" src="../imgs/icon/headr.png"  @click="changeMenuList">
			<ul class="menuList" :style="menuStyle">
				<img src="../imgs/icon/return.png" @click="changeMenuList">
				<li v-for="(item,index) of titleList" :key="index"  @click="changeMenuList">
					<router-link v-if="index != 5" :to="item.url" :class="curActive==index?'cur':''">
						<span>{{item.name}}</span>
					</router-link>
					<a v-else :href="item.url" target="_blank">
						<span>{{item.name}}</span>
					</a>
					<ul>
						<li v-for="(i,index) of item.children" :key="index">
							<router-link :to="i.url">{{i.name}}</router-link>
						</li>
					</ul>
				</li>
			</ul>
		</div>
		<div v-if="imgShow" style="display:flex;justify-content:center;" class="headImg">
			<img :src="urlConfig+bannerImgs[curActive]" alt="图片">
		</div>
	</div>
</template>
<script>
import headerData from './data/headerData'
import {getBanner} from '../api/api'
import apiConfig from "../api/config"
export default {
    data(){
        return {
			titleList:[],
			webBannerImgs:[],
			appBannerImgs:[],
			bannerImgs:[],
			urlConfig:'',
			screenWidth:0,
			screenState:false,
			menuStyle:{
                'transform' : 'translateX(100vw)'
            },
			menuState: false,
			imgShow : true
        }
    },
	props:{
		curActive:0
	},
	mounted(){
        if(this.screenState){
			this.menuStyle.transform = 'translateX(0vw)';
		}else{
			this.$refs.typeArea.style.animation = 'none'
			this.menuStyle.transform = 'translateX(100vw)';
		}
    },
    created(){
		// 初始化获取浏览器窗口大小
        this.screenWidth = document.body.clientWidth;
        // 根据浏览器窗口大小判断是否显示页面内的部分内容
        if(this.screenWidth<1200){
            this.screenState=false;
        }else{
            this.screenState=true;
        }
		this.changeImg()
		
		let that = this
		this.titleList = headerData.titleBar
		this.urlConfig = apiConfig.url
		if(window.localStorage){
			var storage = window.localStorage;
			if(!that.screenState && JSON.parse(storage.getItem("appBannerImgs"))){
				that.bannerImgs = JSON.parse(storage.getItem("appBannerImgs"))
			}else if(that.screenState && JSON.parse(storage.getItem("webBannerImgs"))){
				that.bannerImgs = JSON.parse(storage.getItem("webBannerImgs"))
			}
		}
		getBanner((res) => {
			that.appBannerImgs = []
			that.webBannerImgs = []
			that.bannerImgs = []
			if(res.data.length>0){
				for(let i of res.data){
					if(i.link.split('/')[0] == 'app'){
						// 移动端数据
						that.appBannerImgs.push(i.savePath)
					}else{
						// pc端数据
						that.webBannerImgs.push(i.savePath)
					}
				}
				if(!that.screenState){
					that.bannerImgs = that.appBannerImgs
				}else{
					that.bannerImgs = that.webBannerImgs
				}
				if(window.localStorage){
					var storage = window.localStorage;
					storage.setItem("appBannerImgs",JSON.stringify(that.appBannerImgs));
					storage.setItem("webBannerImgs",JSON.stringify(that.webBannerImgs));
				}
			}
		})
    },
	watch:{
		$route:"changeImg"
	},
	methods:{
		changeMenuList(){
			if(!this.screenState){
				if(this.menuState){
					this.menuState = false
					this.menuStyle.transform = 'translateX(0)';
				}else{
					this.menuState = true
					this.menuStyle.transform = 'translateX(100vw)';
				}
			}
		},
		changeImg(){
			let that = this
			if(!this.screenState){
				if(this.$route.name == 'IndexInfo' || this.$route.name == "QualityInfo" || this.$route.name == "NewsInfo"){
					that.imgShow = false
				}
			}
		}
	}
}
</script>
<style scoped>
	.header{
		width: 100%;
		position: relative;
		background-color: #fff;
	}
	.webName{
		width: 307px;
		height: 25px;
		font-size: 24px;
		font-weight: bold;
		font-stretch: normal;
		letter-spacing: 1px;
		color: #594132;
	}
	.header .typeArea{
		height: 90px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		animation: menuAni 1s linear;
	}
	.typeArea img{
		width: 150px;
		height: 71px;
	}
	.menuList{
		display: flex;
		height: 100%;
	}
	.menuList > li{
		width: 90px;
		position: relative;
		cursor: pointer;
	}
	.menuList > li > a{
		height: 100%;
		display: flex;
		position: relative;
		color: #333;
	}
	.menuList > li > a > span{
		display: inline-block;
		width: 100%;
		position: absolute;
		line-height: 90px;
		text-align: center;

		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #666666;
	}
	.cur{
		background-color: #d6ab7e;
	}
	.cur span{
		color: #fff !important;
	}
	.menuList > li ul{
		position: absolute;
		background-color: #d7aa7ec9;
		border-radius: 0 0 5px 5px;
		width: 140%;
		left: -20%;
		overflow: hidden;
	}
	.menuList > li  li{
		padding: 0;
		display: block;
		height: 0;
		overflow: hidden;
		font-size: 14px;
		line-height: 38px;
		text-align: center;
		transition: height 0.25s linear;
	}
	.menuList > li  li a{
		color: #fff;
	}
	.menuList > li:hover li:last-child{
		margin-bottom: 5px;
	}
	.menuList > li:hover li{
		height: 38px;
	}
	.menuList > li:hover > a{
		color: #d7ab7e;
		font-size: 16px;
	}
	.menuList > li  li:hover{
		background-color:#d7ab7e;
	}
	/* 导航栏动画效果 */
	@keyframes menuAni {
		0%{
			width: 1100px;
			opacity: 0;
		}
		100%{
			width: 1200px;
			opacity: 1;
		}
	}
	.headImg>img{
		width: 100%;
		min-width: 1920px;
	}
	@media only screen and (max-width: 1199px) {
		/* .header{
			width: 100vw; 
		} */
		.header .typeArea{
			width: 100%;
			padding: 0 25px;
			box-sizing: border-box;
			height: 60px;
			overflow: hidden;
		}
		.webName{
			font-size: 14px;
		}
		.headImg{
			min-width: 0;
			overflow: auto;
			width: 100vw;
		}
		.headImg>img{
			width: 100%;
			min-width: auto;
		}
		.header .typeArea .headerMenu{
			height: 23px;
			width: 26px;
		}
		.menuList{
			position: fixed;
			width: 100vw;
			height: 100vh;
			top: 0;
			left: 0;
			flex-direction: column;
			z-index: 99;
			overflow: auto;
			transition: all .4s linear;
			flex: 1;
		}
		.menuList>img{
			position: absolute;
			z-index: 999;
			top: 20px;
			right: 20px;
			width: 20px;
			height: 20px;
		}
		.menuList>li{
			width: 100vw;
			height: 125px;
			color: #fff;
			font-size: 20px;
		}
		.menuList > li > a{
			height: 125px;
		}
		.menuList > li > a > span{
			width: auto;
			left: 70px;
			color: #fff;
			line-height: 125px;
		}
		.menuList>li:nth-child(2){
			background-color: #3e3930;
		}
		.menuList>li:nth-child(3){
			background-color: #3b3427;
		}
		.menuList>li:nth-child(4){
			background-color: #372e1c;
		}
		.menuList>li:nth-child(5){
			background-color: #342b19;
		}
		.menuList>li:nth-child(6){
			background-color: #302715;
		}
		.menuList>li:nth-child(7){
			background-color: #2c2311;
		}
		.menuList>li:nth-child(8){
			background-color: #271f12;
		}
		.cur{
			background-color: transparent;
		}
	}
</style>